.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.App-logo {
  height: 5rem;
  pointer-events: none;
}

h1 {
  padding: 10px 50px 50px 50px;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #3da68d;
  text-decoration: none;
}
